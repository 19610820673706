export const periods = [
  { value: "today", label: "Today" },
  { value: "tomorrow", label: "Tomorrow" },
  { value: "week", label: "Week" },
  { value: "all", label: "All" },
];

export const markets = [
  {name: 'FT 1X2: 1', code: '1', outcomeID: 1, marketID: 1, specifier: ""},
  {name: 'FT 1X2: 2', code: '2', outcomeID: 3, marketID: 1, specifier: ""},
  {name: 'FT 1X2: X', code: '0', outcomeID: 2, marketID: 1, specifier: ""},
  {name: 'FT 1X2/BTS: 1 and No', code: '1/+-', outcomeID: 80, marketID: 35, specifier: ""},
  {name: 'FT 1X2/BTS: 1 and Yes', code: '1/++', outcomeID: 78, marketID: 35, specifier: ""},
  {name: 'FT 1X2/BTS: 2 and No', code: '2/+-', outcomeID: 88, marketID: 35, specifier: ""},
  {name: 'FT 1X2/BTS: 2 and Yes', code: '2/++', outcomeID: 86, marketID: 35, specifier: ""},
  {name: 'FT 1X2/BTS: X and No', code: '0/+-', outcomeID: 84, marketID: 35, specifier: ""},
  {name: 'FT 1X2/BTS: X and Yes', code: '0/++', outcomeID: 12, marketID: 35, specifier: ""},
  {name: 'FT 1X2/Goals: 1 and Over 1.5', code: '1/1.5+', outcomeID: 796, marketID: 37, specifier: "total=1.5"},
  {name: 'FT 1X2/Goals: 1 and Over 2.5', code: '1/2.5+', outcomeID: 796, marketID: 37, specifier: "total=2.5"},
  {name: 'FT 1X2/Goals: 1 and Over 3.5', code: '1/3.5+', outcomeID: 796, marketID: 37, specifier: "total=3.5"},
  {name: 'FT 1X2/Goals: 1 and Under 1.5', code: '1/1.5-', outcomeID: 794, marketID: 37, specifier: "total=1.5"},
  {name: 'FT 1X2/Goals: 1 and Under 2.5', code: '1/2.5-', outcomeID: 794, marketID: 37, specifier: "total=2.5"},
  {name: 'FT 1X2/Goals: 1 and Under 3.5', code: '1/3.5-', outcomeID: 794, marketID: 37, specifier: "total=3.5"},
  {name: 'FT 1X2/Goals: 2 and Over 1.5', code: '2/1.5+', outcomeID: 804, marketID: 37, specifier: "total=1.5"},
  {name: 'FT 1X2/Goals: 2 and Over 2.5', code: '2/2.5+', outcomeID: 804, marketID: 37, specifier: "total=2.5"},
  {name: 'FT 1X2/Goals: 2 and Over 3.5', code: '2/3.5+', outcomeID: 804, marketID: 37, specifier: "total=3.5"},
  {name: 'FT 1X2/Goals: 2 and Under 1.5', code: '2/1.5-', outcomeID: 802, marketID: 37, specifier: "total=1.5"},
  {name: 'FT 1X2/Goals: 2 and Under 2.5', code: '2/2.5-', outcomeID: 802, marketID: 37, specifier: "total=3.5"},
  {name: 'FT 1X2/Goals: 2 and Under 3.5', code: '2/3.5-', outcomeID: 802, marketID: 37, specifier: "total=1.5"},
  {name: 'FT 1X2/Goals: X and Over 1.5', code: 'X/1.5+', outcomeID: 800, marketID: 37, specifier: "total=1.5"},
  {name: 'FT 1X2/Goals: X and Over 2.5', code: 'X/2.5+', outcomeID: 800, marketID: 37, specifier: "total=2.5"},
  {name: 'FT 1X2/Goals: X and Over 3.5', code: 'X/3.5+', outcomeID: 800, marketID: 37, specifier: "total=3.5"},
  {name: 'FT 1X2/Goals: X and Under 1.5', code: 'X/1.5-', outcomeID: 798, marketID: 37, specifier: "total=1.5"},
  {name: 'FT 1X2/Goals: X and Under 2.5', code: 'X/2.5-', outcomeID: 798, marketID: 37, specifier: "total=1.5"},
  {name: 'FT 1X2/Goals: X and Under 3.5', code: 'X/3.5-', outcomeID: 798, marketID: 37, specifier: "total=1.5"},
  {name: 'FT BTS: No', code: "+-", outcomeID: 76, marketID: 29, specifier: ""},
  {name: 'FT BTS: Yes', code: "++", outcomeID: 74, marketID: 29, specifier: ""},
  {name: 'FT DC Under/Over: 1X and Over 1.5', code: '10/1.5+'},
  {name: 'FT DC Under/Over: 1X and Over 2.5', code: '10/2.5+'},
  {name: 'FT DC Under/Over: 1X and Over 3.5', code: '10/3.5+'},
  {name: 'FT DC Under/Over: 1X and Over 4.5', code: '10/4.5+'},
  {name: 'FT DC Under/Over: 1X and Over 5.5', code: '10/5.5+'},
  {name: 'FT DC: 12', code: "12", outcomeID: 10, marketID: 10, specifier: ""},
  {name: 'FT DC: 1X', code: "10", outcomeID: 9, marketID: 10, specifier: ""},
  {name: 'FT DC: X2', code: "02", outcomeID: 11, marketID: 10, specifier: ""},
  {name: 'FT DC/Goals: 12 and Over 1.5', code: '12/1.5+', outcomeID: 94, marketID: 36, specifier: "total=2.5"},
  {name: 'FT DC/Goals: 12 and Over 2.5', code: '12/2.5+'},
  {name: 'FT DC/Goals: 12 and Over 3.5', code: '12/3.5+'},
  {name: 'FT DC/Goals: 12 and Over 4.5', code: '12/4.5+'},
  {name: 'FT DC/Goals: 12 and Over 5.5', code: '12/5.5+'},
  {name: 'FT DC/Goals: 12 and Under 1.5', code: '12/1.5-'},
  {name: 'FT DC/Goals: 12 and Under 2.5', code: '12/2.5-'},
  {name: 'FT DC/Goals: 12 and Under 3.5', code: '12/3.5-'},
  {name: 'FT DC/Goals: 12 and Under 4.5', code: '12/4.5-'},
  {name: 'FT DC/Goals: 12 and Under 5.5', code: '12/5.5-'},
  {name: 'FT DC/Goals: 1X and Under 1.5', code: '1X/1.5-'},
  {name: 'FT DC/Goals: 1X and Under 2.5', code: '1X/2.5-'},
  {name: 'FT DC/Goals: 1X and Under 3.5', code: '1X/3.5-'},
  {name: 'FT DC/Goals: 1X and Under 4.5', code: '1X/4.5-'},
  {name: 'FT DC/Goals: 1X and Under 5.5', code: '1X/5.5-'},
  {name: 'FT DC/Goals: X2 and Over 1.5', code: 'X2/1.5+'},
  {name: 'FT DC/Goals: X2 and Over 2.5', code: 'X2/2.5+'},
  {name: 'FT DC/Goals: X2 and Over 3.5', code: 'X2/3.5+'},
  {name: 'FT DC/Goals: X2 and Over 4.5', code: 'X2/4.5+'},
  {name: 'FT DC/Goals: X2 and Over 5.5', code: 'X2/5.5+'},
  {name: 'FT DC/Goals: X2 and Under 1.5', code: 'X2/1.5-'},
  {name: 'FT DC/Goals: X2 and Under 2.5', code: 'X2/2.5-'},
  {name: 'FT DC/Goals: X2 and Under 3.5', code: 'X2/3.5-'},
  {name: 'FT DC/Goals: X2 and Under 4.5', code: 'X2/4.5-'},
  {name: 'FT DC/Goals: X2 and Under 5.5', code: 'X2/5.5-'},
  {name: 'FT DND: 1', code: "10.", outcomeID: 4, marketID: 11, specifier: ""},
  {name: 'FT DND: 2', code: "20.", outcomeID: 5, marketID: 11, specifier: ""},
  {name: 'FT Goals/BTS: Over 2.5 and No', code: '2.5+/+-', outcomeID: 94, marketID: 36, specifier: "total=2.5"},
  {name: 'FT Goals/BTS: Over 2.5 and Yes', code: '2.5+/++', outcomeID: 90, marketID: 36, specifier: "total=2.5"},
  {name: 'FT Goals/BTS: Under 2.5 and No', code: '2.5-/+-', outcomeID: 96, marketID: 36, specifier: "total=2.5"},
  {name: 'FT Goals/BTS: Under 2.5 and Yes', code: '2.5-/++', outcomeID: 92, marketID: 36, specifier: "total=2.5"},
  {name: 'FT Handicap: (0:1): 1', code: "1-1."},
  {name: 'FT Handicap: (0:1): 2', code: "1-2."},
  {name: 'FT Handicap: (0:1): X', code: "1-0."},
  {name: 'FT Handicap: (0:2): 1', code: "2-1."},
  {name: 'FT Handicap: (0:2): 2', code: "2-2."},
  {name: 'FT Handicap: (0:2): X', code: "2-0."},
  {name: 'FT Handicap: (0:3): 1', code: "3-1."},
  {name: 'FT Handicap: (0:3): 2', code: "3-2."},
  {name: 'FT Handicap: (0:3): X', code: "3-0."},
  {name: 'FT Handicap: (0:4): 1', code: "4-1."},
  {name: 'FT Handicap: (0:4): 2', code: "4-2."},
  {name: 'FT Handicap: (0:4): X', code: "4-0."},
  {name: 'FT Handicap: (0:5): 1', code: "5-1."},
  {name: 'FT Handicap: (0:5): 2', code: "5-2."},
  {name: 'FT Handicap: (0:5): X', code: "5-0."},
  {name: 'FT Handicap: (0:6): 1', code: "6-1."},
  {name: 'FT Handicap: (0:6): 2', code: "6-2."},
  {name: 'FT Handicap: (0:6): X', code: "6-0."},
  {name: 'FT Handicap: (1:0): 1', code: "1+1."},
  {name: 'FT Handicap: (1:0): 2', code: "1+2."},
  {name: 'FT Handicap: (1:0): X', code: "1+0."},
  {name: 'FT Handicap: (2:0): 1', code: "2+1."},
  {name: 'FT Handicap: (2:0): 2', code: "2+2."},
  {name: 'FT Handicap: (2:0): X', code: "2+0."},
  {name: 'FT Handicap: (3:0): 1', code: "3+1."},
  {name: 'FT Handicap: (3:0): 2', code: "3+2."},
  {name: 'FT Handicap: (3:0): X', code: "3+0."},
  {name: 'FT Handicap: (4:0): 1', code: "4+1."},
  {name: 'FT Handicap: (4:0): 2', code: "4+2."},
  {name: 'FT Handicap: (4:0): X', code: "4+0."},
  {name: 'FT Handicap: (5:0): 1', code: "5+1."},
  {name: 'FT Handicap: (5:0): 2', code: "5+2."},
  {name: 'FT Handicap: (5:0): X', code: "5+0."},
  {name: 'FT Handicap: (6:0): 1', code: "6+1."},
  {name: 'FT Handicap: (6:0): 2', code: "6+2."},
  {name: 'FT Handicap: (6:0): X', code: "6+0."},
  {name: 'FT Handicap: 1', code: "1.", outcomeID: '', marketID: 16, specifier: ""},
  {name: 'FT Handicap: 2', code: "2."},
  {name: 'FT Handicap: X', code: "0."},
  {name: 'FT Odd/Even: Even', code: '+', outcomeID: 72, marketID: 26, specifier: ""},
  {name: 'FT Odd/Even: Odd', code: '-', outcomeID: 70, marketID: 26, specifier: ""},
  {name: 'FT Under/Over: (0.5) Over', code: '0.5+', outcomeID: 12, marketID: 18, specifier: "total=0.5"},
  {name: 'FT Under/Over: (0.5) Under', code: '0.5-', outcomeID: 13, marketID: 18, specifier: "total=0.5"},
  {name: 'FT Under/Over: (1.5) Over', code: '1.5+', outcomeID: 12, marketID: 18, specifier: "total=1.5"},
  {name: 'FT Under/Over: (1.5) Under', code: '1.5-', outcomeID: 13, marketID: 18, specifier: "total=1.5"},
  {name: 'FT Under/Over: (10.5) Over', code: '10.5+', outcomeID: 12, marketID: 18, specifier: "total=10.5"},
  {name: 'FT Under/Over: (10.5) Under', code: '10.5-', outcomeID: 13, marketID: 18, specifier: "total=10.5"},
  {name: 'FT Under/Over: (2.5) Over', code: '2.5+', outcomeID: 12, marketID: 18, specifier: "total=2.5"},
  {name: 'FT Under/Over: (2.5) Under', code: '2.5-', outcomeID: 13, marketID: 18, specifier: "total=2.5"},
  {name: 'FT Under/Over: (3.5) Over', code: '3.5+', outcomeID: 12, marketID: 18, specifier: "total=3.5"},
  {name: 'FT Under/Over: (3.5) Under', code: '3.5-', outcomeID: 13, marketID: 18, specifier: "total=3.5"},
  {name: 'FT Under/Over: (4.5) Over', code: '4.5+', outcomeID: 12, marketID: 18, specifier: "total=4.5"},
  {name: 'FT Under/Over: (4.5) Under', code: '4.5-', outcomeID: 13, marketID: 18, specifier: "total=4.5"},
  {name: 'FT Under/Over: (5.5) Over', code: '5.5+', outcomeID: 12, marketID: 18, specifier: "total=5.5"},
  {name: 'FT Under/Over: (5.5) Under', code: '5.5-', outcomeID: 13, marketID: 18, specifier: "total=5.5"},
  {name: 'FT Under/Over: (6.5) Over', code: '6.5+', outcomeID: 12, marketID: 18, specifier: "total=6.5"},
  {name: 'FT Under/Over: (6.5) Under', code: '6.5-', outcomeID: 13, marketID: 18, specifier: "total=6.5"},
  {name: 'FT Under/Over: (7.5) Over', code: '7.5+', outcomeID: 12, marketID: 18, specifier: "total=7.5"},
  {name: 'FT Under/Over: (7.5) Under', code: '7.5-', outcomeID: 13, marketID: 18, specifier: "total=7.5"},
  {name: 'FT Under/Over: (8.5) Over', code: '8.5+', outcomeID: 12, marketID: 18, specifier: "total=8.5"},
  {name: 'FT Under/Over: (8.5) Under', code: '8.5-', outcomeID: 13, marketID: 18, specifier: "total=8.5"},
  {name: 'FT Under/Over: (9.5) Over', code: '9.5+', outcomeID: 12, marketID: 18, specifier: "total=9.5"},
  {name: 'FT Under/Over: (9.5) Under', code: '9.5-', outcomeID: 13, marketID: 18, specifier: "total=9.5"},
  {name: 'HSH: 1st', code: '1+', outcomeID: 436, marketID: 52, specifier: ""},
  {name: 'HSH: 2nd', code: '2+', outcomeID: 438, marketID: 52, specifier: ""},
  {name: 'HSH: X', code: '0+', outcomeID: 440, marketID: 52, specifier: ""},
  {name: 'HT 1X2: 1', code: '/1', outcomeID: 1, marketID: 60, specifier: ""},
  {name: 'HT 1X2: 2', code: '/2', outcomeID: 3, marketID: 60, specifier: ""},
  {name: 'HT 1X2: X', code: '/0', outcomeID: 2, marketID: 60, specifier: ""},
  {name: 'HT DC: 12', code: '/12', outcomeID: 10, marketID: 63, specifier: ""},
  {name: 'HT DC: 1X', code: '/10', outcomeID: 9, marketID: 63, specifier: ""},
  {name: 'HT DC: X2', code: '/02', outcomeID: 11, marketID: 63, specifier: ""},
  {name: 'HT Under/Over: (0.5) Over', code: '/0.5+', outcomeID: 12, marketID: 68, specifier: "total=0.5"},
  {name: 'HT Under/Over: (0.5) Under', code: '/0.5-', outcomeID: 13, marketID: 68, specifier: "total=0.5"},
  {name: 'HT Under/Over: (1.5) Over', code: '/1.5+', outcomeID: 12, marketID: 68, specifier: "total=1.5"},
  {name: 'HT Under/Over: (1.5) Under', code: '/1.5-', outcomeID: 13, marketID: 68, specifier: "total=1.5"},
  {name: 'HT Under/Over: (10.5) Over', code: '/10.5+', outcomeID: 12, marketID: 68, specifier: "total=10.5"},
  {name: 'HT Under/Over: (10.5) Under', code: '/10.5-', outcomeID: 13, marketID: 68, specifier: "total=10.5"},
  {name: 'HT Under/Over: (2.5) Over', code: '/2.5+', outcomeID: 12, marketID: 68, specifier: "total=2.5"},
  {name: 'HT Under/Over: (2.5) Under', code: '/2.5-', outcomeID: 13, marketID: 68, specifier: "total=2.5"},
  {name: 'HT Under/Over: (3.5) Over', code: '/3.5+', outcomeID: 12, marketID: 68, specifier: "total=3.5"},
  {name: 'HT Under/Over: (3.5) Under', code: '/3.5-', outcomeID: 12, marketID: 68, specifier: "total=3.5"},
  {name: 'HT Under/Over: (4.5) Over', code: '/4.5+', outcomeID: 12, marketID: 68, specifier: "total=4.5"},
  {name: 'HT Under/Over: (4.5) Under', code: '/4.5-', outcomeID: 13, marketID: 68, specifier: "total=4.5"},
  {name: 'HT Under/Over: (5.5) Over', code: '/5.5+', outcomeID: 12, marketID: 68, specifier: "total=5.5"},
  {name: 'HT Under/Over: (5.5) Under', code: '/5.5-', outcomeID: 13, marketID: 68, specifier: "total=5.5"},
  {name: 'HT Under/Over: (6.5) Over', code: '/6.5+', outcomeID: 12, marketID: 68, specifier: "total=6.5"},
  {name: 'HT Under/Over: (6.5) Under', code: '/6.5-', outcomeID: 13, marketID: 68, specifier: "total=6.5"},
  {name: 'HT Under/Over: (7.5) Over', code: '/7.5+', outcomeID: 12, marketID: 68, specifier: "total=7.5"},
  {name: 'HT Under/Over: (7.5) Under', code: '/7.5-', outcomeID: 13, marketID: 68, specifier: "total=7.5"},
  {name: 'HT Under/Over: (8.5) Over', code: '/8.5+', outcomeID: 12, marketID: 68, specifier: "total=8.5"},
  {name: 'HT Under/Over: (8.5) Under', code: '/8.5-', outcomeID: 13, marketID: 68, specifier: "total=8.5"},
  {name: 'HT Under/Over: (9.5) Over', code: '/9.5+', outcomeID: 12, marketID: 68, specifier: "total=9.5"},
  {name: 'HT Under/Over: (9.5) Under', code: '/9.5-', outcomeID: 13, marketID: 68, specifier: "total=9.5"},
  {name: 'HTFT: 1/1', code: '1/1', outcomeID: 418, marketID: 47, specifier: ""},
  {name: 'HTFT: 1/2', code: '1/2', outcomeID: 420, marketID: 47, specifier: ""},
  {name: 'HTFT: 1/X', code: '1/0', outcomeID: 422, marketID: 47, specifier: ""},
  {name: 'HTFT: 2/1', code: '2/1', outcomeID: 424, marketID: 47, specifier: ""},
  {name: 'HTFT: 2/2', code: '2/2', outcomeID: 426, marketID: 47, specifier: ""},
  {name: 'HTFT: 2/X', code: '2/0', outcomeID: 428, marketID: 47, specifier: ""},
  {name: 'HTFT: X/1', code: '0/1', outcomeID: 430, marketID: 47, specifier: ""},
  {name: 'HTFT: X/2', code: '0/2', outcomeID: 432, marketID: 47, specifier: ""},
  {name: 'HTFT: X/X', code: '0/0', outcomeID: 434, marketID: 47, specifier: ""},
]

export const LiveEventsOverview = [
  {
    sport: "Soccer",
    id: 1,
    markets: [
      {
        id: "1",
        name: "1X2",
        specifier: "",
        outcomes: [
          { name: "1", id: 1, type: "1" },
          { name: "X", id: 2, type: "x" },
          { name: "2", id: 3, type: "2" },
        ],
      },
      {
        id: "14",
        name: "GG/NG",
        specifier: "",
        outcomes: [
          { name: "GG", id: 41, type: "goal" },
          { name: "NG", id: 42, type: "nogoal" },
        ],
      },
      {
        id: "5",
        name: "Over/Under",
        specifier: "total=2.5",
        outcomes: [
          { name: "Over", id: 12, type: "o" },
          { name: "Under", id: 13, type: "u" },
        ],
      },
    ],
  },
  {
    sport: "Basketball",
    id: 2,
    markets: [
      {
        name: "Winner",
        id: "43",
        hasSpread: false,
        outcomes: [
          { name: "1", id: 17, type: "1" },
          { name: "2", id: 18, type: "2" },
        ],
      },
      {
        name: "Handicap",
        id: "4",
        hasSpread: true,
        outcomes: [
          { name: "1", id: 7, type: "1" },
          { name: "2", id: 8, type: "2" },
        ],
      },
      {
        name: "Odd/Even",
        id: "51",
        outcomes: [
          { name: "Odd", id: 4572, type: "odd" },
          { name: "Even", id: 4574, type: "even" },
        ],
      },
    ],
  },
  {
    sport: "Baseball",
    id: 3,
    markets: [
      {
        name: "Money Line",
        id: "14897",
        outcomes: [
          { name: "1", id: 17, type: "1" },
          { name: "2", id: 18, type: "2" },
        ],
      },
      {
        name: "Over/Under",
        id: 160,
        hasSpread: true,
        outcomes: [
          { name: "Over", id: 1 },
          { name: "Under", id: 2 },
        ],
      },
      {
        name: "Handicap",
        id: 305,
        outcomes: [
          { name: "1 H", id: 1 },
          { name: "2 H", id: 2 },
        ],
      },
    ],
  },
  {
    sport: "Ice Hockey",
    id: 4,
    markets: [
      {
        name: "1X2",
        id: "",
        outcomes: [
          { name: "1", id: 1 },
          { name: "X", id: 2 },
          { name: "2", id: 3 },
        ],
      },
      {
        name: "Total goals",
        id: 160,
        hasSpread: true,
        outcomes: [
          { name: "Over", id: 1 },
          { name: "Under", id: 2 },
        ],
      },
      {
        name: "Next goal",
        id: 195,
        outcomes: [
          { name: "1", id: 1 },
          { name: "X", id: 2 },
          { name: "2", id: 3 },
        ],
      },
    ],
  },
  {
    sport: "Tennis",
    id: 5,
    markets: [
      {
        name: "Winner",
        id: "186",
        hasSpread: false,
        outcomes: [
          { name: "1", id: 4, type: "1" },
          { name: "2", id: 5, type: "2" },
        ],
      },
      {
        name: "Over/Under",
        id: "189",
        hasSpread: true,
        outcomes: [
          { name: "Over", id: 12, type: "over" },
          { name: "Under", id: 13, type: "under" },
        ],
      },
      {
        name: "Total Games Odd/Even",
        id: "37",
        hasSpread: false,
        outcomes: [
          { name: "Odd", id: 171, type: "odd" },
          { name: "Even", id: 172, type: "even" },
        ],
      },
    ],
  },
  {
    sport: "Handball",
    id: 6,
    markets: [
      {
        id: 110,
        name: "1X2",
        outcomes: [
          { name: "1", id: 1 },
          { name: "X", id: 2 },
          { name: "2", id: 3 },
        ],
      },
      {
        id: 302,
        name: "Total goals, regular time only",
        outcomes: [
          { name: "GG", id: 1 },
          { name: "NG", id: 2 },
        ],
      },
      {
        id: 160,
        name: "Total goals home team",
        hasSpread: true,
        outcomes: [
          { name: "Over", id: 1 },
          { name: "Under", id: 2 },
        ],
      },
    ],
  },
  {
    sport: "Football",
    id: 16,
    markets: [
      {
        id: 110,
        name: "1X2",
        outcomes: [
          { name: "1", id: 1 },
          { name: "X", id: 2 },
          { name: "2", id: 3 },
        ],
      },
      {
        id: 302,
        name: "Total goals, regular time only",
        outcomes: [
          { name: "GG", id: 1 },
          { name: "NG", id: 2 },
        ],
      },
      {
        id: 160,
        name: "Total goals home team",
        hasSpread: true,
        outcomes: [
          { name: "Over", id: 1 },
          { name: "Under", id: 2 },
        ],
      },
    ],
  },
  {
    sport: "Snooker",
    id: 19,
    markets: [
      {
        id: "17340",
        name: "Match Winner",
        hasSpread: false,
        outcomes: [
          { name: "1", id: 1, type: "1" },
          { name: "X", id: 2, type: "x" },
          { name: "2", id: 3, type: "2" },
        ],
      },
      {
        id: "999",
        name: "Who will win the frame?",
        hasSpread: false,
        outcomes: [
          { name: "1", id: 17, type: "1" },
          { name: "2", id: 18, type: "2" },
        ],
      },
    ],
  },
  {
    sport: "Table Tennis",
    id: 20,

    markets: [
      {
        name: "Which team will win the match?",
        id: "177",
        hasSpread: false,
        outcomes: [
          { name: "1", id: 17, type: "1" },
          { name: "2", id: 18, type: "2" },
        ],
      },
      {
        id: "5",
        name: "Total Points",
        hasSpread: true,
        outcomes: [
          { name: "Over", id: 11, type: "o" },
          { name: "Under", id: 12, type: "u" },
        ],
      },
      {
        name: "Which team will win the set?",
        id: "7",
        hasSpread: true,
        outcomes: [
          { name: "1", id: 17, type: "1" },
          { name: "2", id: 18, type: "2" },
        ],
      },
    ],
  },
  {
    sport: "Cricket",
    id: 21,
    markets: [
      {
        id: 9841,
        name: "Winner",
        outcomes: [
          { name: "1", id: 1 },
          { name: "2", id: 2 },
        ],
      },
      {
        id: 302,
        name: "Next dismissal (caught / not caught)",
        outcomes: [
          { name: "Caught", id: 1 },
          { name: "Not caught", id: 2 },
        ],
      },
      {
        id: 160,
        name: "Total runs",
        hasSpread: true,
        outcomes: [
          { name: "Over", id: 1 },
          { name: "Under", id: 2 },
        ],
      },
    ],
  },
  {
    sport: "Dart",
    id: 22,
    markets: [
      {
        id: "11478",
        name: "Winner",
        outcomes: [
          { name: "1", id: 1, type: "1" },
          { name: "X", id: 2, type: "x" },
          { name: "2", id: 3, type: "2" },
        ],
      },
    ],
  },
  {
    sport: "Volleyball",
    id: 23,
    markets: [
      {
        id: 110,
        name: "Winner",
        outcomes: [
          { name: "1", id: 1 },
          { name: "2", id: 2 },
        ],
      },
      {
        id: 302,
        name: "Total Points",
        hasSpread: true,
        outcomes: [
          { name: "Over", id: 1 },
          { name: "Under", id: 2 },
        ],
      },
      {
        id: 160,
        name: "Which team will win the set?",
        outcomes: [
          { name: "Over", id: 1 },
          { name: "Under", id: 2 },
        ],
      },
    ],
  },
  {
    sport: "Futsal",
    id: 29,
    markets: [
      {
        id: 110,
        name: "1X2",
        outcomes: [
          { name: "1", id: 1 },
          { name: "1", id: 2 },
          { name: "2", id: 3 },
        ],
      },
      {
        id: 302,
        name: "Total Goals",
        hasSpread: true,
        outcomes: [
          { name: "Over", id: 1 },
          { name: "Under", id: 2 },
        ],
      },
      {
        id: 160,
        name: "Asian Handicap",
        outcomes: [
          { name: "1 AH", id: 1 },
          { name: "2 AH", id: 2 },
        ],
      },
    ],
  },
  {
    sport: "ESport Dota 2",
    id: 112,
    markets: [
      {
        id: 9388,
        name: "Winner",
        outcomes: [
          { name: "1", id: 1 },
          { name: "2", id: 3 },
        ],
      },
      {
        id: 10020,
        name: "Map - Which team will win",
        outcomes: [
          { name: "1", id: 1 },
          { name: "2", id: 2 },
        ],
      },
      {
        id: 10016,
        name: "Total Maps",
        hasSpread: true,
        outcomes: [
          { name: "Over", id: 1 },
          { name: "Under", id: 2 },
        ],
      },
    ],
  },
  {
    sport: "ESport League of Legends",
    id: 111,
    markets: [
      {
        id: 9388,
        name: "Winner",
        outcomes: [
          { name: "1", id: 1 },
          { name: "2", id: 2 },
        ],
      },
      {
        id: 10020,
        name: "Map - Which team will win",
        outcomes: [
          { name: "1", id: 1 },
          { name: "2", id: 2 },
        ],
      },
      {
        id: 10016,
        name: "Total Maps",
        hasSpread: true,
        outcomes: [
          { name: "Over", id: 1 },
          { name: "Under", id: 2 },
        ],
      },
    ],
  },
  {
    sport: "Rugby",
    id: 254,
    markets: [
      {
        id: 9388,
        name: "1X2",
        outcomes: [
          { name: "1", id: 1 },
          { name: "X", id: 2 },
          { name: "2", id: 3 },
        ],
      },
      {
        id: 10020,
        name: "Over/Under",
        hasSpread: true,
        outcomes: [
          { name: "Over", id: 1 },
          { name: "Under", id: 2 },
        ],
      },
      {
        id: 10016,
        name: "Total Goals Home",
        outcomes: [
          { name: "Over", id: 1 },
          { name: "Under", id: 2 },
        ],
      },
    ],
  },
];

export const matchStatus = (status) => {
  switch (status) {
    case "not_started":
      return "Not Started";
    case "1p":
      return "1st Period";
    case "2p":
      return "2nd Period";
    case "1set":
      return "1st Set";
    case "2set":
      return "2nd Set";
    case "3set":
      return "3rd Set";
    case "4set":
      return "4th Set";
    case "5set":
      return "5th Set";
    case "6set":
      return "6th Set";
    case "7set":
      return "7th Set";
    case "1q":
      return "1st Quarter";
    case "2q":
      return "2nd Quarter";
    case "3q":
      return "3rd Quarter";
    case "4q":
      return "4th Quarter";
    case "ot":
      return "Overtime";
    case "2p_ot":
      return "2nd Period Overtime";
    case "in_progress":
      return "In Progress";
    case "lunch_break":
      return "Lunch Break";
    case "injury_break":
      return "Injury Break";
    case "1g":
      return "1st Game";
    case "2g":
      return "2nd Game";
    case "3g":
      return "3rd Game";
    case "4g":
      return "4th Game";
    case "5g":
      return "5th Game";
    case "paused":
      return "Paused";
    case "ended":
      return "Ended";
    default:
      break;
  }
};
